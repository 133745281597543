<template>
  <Checkout />
</template>

<script>
import Checkout from "@/components/checkout/Checkout";

export default {
  name: "CheckoutPage",
  props: {},
  components: {
    Checkout,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
