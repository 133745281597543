<template>
  <div class="checkout-pay">
    <SectionWithBg
      heading-position="left"
      single-line-heading
      heading-top
      growing-body
      style="padding-top: 0"
    >
      <template #heading> Total: $ {{ total }} </template>
      <template #body>
        <form
          @submit.prevent="createPaymentIntent"
          method="post"
          class="checkout-pay__form"
        >
          <Input
            v-model="form.email.value"
            :error="form.email.error"
            type="text"
            name="email"
            label="Email"
            @input="clearError('email', form)"
            @blur="validateField('email', form)"
          />

          <label for="card-element" class="checkout-pay__label">
            Credit or debit card
          </label>
          <div class="mt-3 checkout-pay__card">
            <div id="card-element" />
          </div>

          <div class="checkout-pay__error mb-10">{{ cardError }}</div>
          <transition name="fade">
            <Error v-if="codeError" class="mb-5">
              <template #heading>{{ codeError }}</template>
              <template #body>
                Please try again or contact us at
                <a href="mailto:info@arctic-con.com"> info@arctic-con.com </a>
              </template>
            </Error>
          </transition>

          <Preloader :loading="loading" />
          <Button :disabled="emptyCard || loading" fullWidth>
            Submit Payment
          </Button>
        </form>
      </template>
    </SectionWithBg>
  </div>
</template>

<script>
import SectionWithBg from "@/components/common/SectionWithBg";
import Preloader from "@/components/common/Preloader";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import Error from "@/components/common/Error";
import { validateField, clearError } from "@/assets/js/validation";
import { transformForm } from "@/assets/js/utils";
import { mapActions, mapState } from "vuex";

// eslint-disable-next-line
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
const elements = stripe.elements();
const style = {
  base: {
    border: "1px solid #D8D8D8",
    borderRadius: "4px",
    color: "#000",
  },
  invalid: {
    // All of the error styles go inside of here.
  },
};
let card = elements.create("card", { style: style }); // Create an instance of the card Element.

export default {
  name: "CheckoutForm",
  props: {
    ticketsQuantity: {
      type: Object,
      default: () => ({}),
    },
    ticketsPrices: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["payment-success"],
  components: {
    SectionWithBg,
    Input,
    Button,
    Error,
    Preloader,
  },
  data: () => ({
    loading: false,
    clientSecret: null,
    codeError: null,
    emptyCard: true,
    cardError: "",
    form: {
      email: {
        value: "",
        error: "",
        rules: ["required", "email"],
      },
    },
  }),
  computed: {
    ...mapState(["currentEventID"]),
    total() {
      let price = 0;
      Object.keys(this.ticketsQuantity).map((i) => {
        price = price + this.ticketsPrices[i] * this.ticketsQuantity[i];
      });
      if (isNaN(price)) price = 0;
      return price;
    },
  },
  mounted() {
    this.$nextTick(function () {
      let self = this;
      card.mount("#card-element"); // Add an instance of the card Element into the `card-element` <div>.
      card.on("change", function (event) {
        self.emptyCard = event.empty;
        self.cardError = event.error ? event.error.message : "";
      });
    });
  },
  methods: {
    ...mapActions(["POST"]),
    transformForm,
    validateField,
    clearError,
    createPaymentIntent() {
      validateField("email", this.form);
      if (this.form.email.error) return;
      this.loading = true;
      this.codeError = null;

      const query = {
        event: this.currentEventID,
        codes_requested: this.ticketsQuantity,
        receipt_email: this.form.email.value,
      };

      this.POST({
        route: `/ticketing/public/event/${this.currentEventID}/purchase`,
        data: query,
      })
        .then((res) => {
          console.log(res);
          this.payWithCard(res.data.client_secret);
        })
        .catch((error) => {
          this.codeError =
            error.response.data.msg || "Sorry, something went wrong";
          this.loading = false;
        });
    },
    payWithCard(secret) {
      let self = this;
      stripe
        .confirmCardPayment(secret, {
          payment_method: {
            card: card,
          },
        })
        .then(function (result) {
          console.log(result);
          if (result.error) {
            self.codeError = result.error.message;
            self.loading = false;
          } else {
            self.loading = false;
            self.$emit("payment-success", result.paymentIntent.id);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.checkout-pay {
  &__card {
    border-bottom: 2px solid $black;
    padding: 16px 16px 16px 32px;
  }

  &__label {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.3;
    color: $black;
    margin-left: 6%;
  }

  &__error {
    padding: 2px 16px 0 32px;
    font-size: 14px;
    line-height: 1.2;
    color: $error-red;
    text-align: left;
    font-family: $openSans;
  }

  @media (min-width: $media-sm) {
    &__form {
      width: 80%;
    }
  }
}
</style>
